import React from 'react';
import HeaderAccueil from './components/HeaderAccueil';

function TokenExpirated() {
  return (
    <div>
        <HeaderAccueil />
        <div className="bg-white p-t-100 p-b-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 dis-flex itm-center">
                        <div>
                            <h3 className="text-dark fw-700 f-f-poppins lh-1-5">Session terminée !</h3>

                            <p className="text-black fs-16 fw-300 m-b-60">Lacus ac morbi elementum iaculis cursus.</p>

                            <a className="btn-theme fw-600 f-f-poppins" href="/connexion">Connexion</a>
                        </div>
                    </div>
                    <div className="col-md-5 dis-flex offset-md-1">
                        <img className="w-full" src="img/token-expire.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default TokenExpirated;
