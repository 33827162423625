// eslint-disable-next-line
import React, { Component } from 'react';
// import FeatherIcon from 'feather-icons-react';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { NavLink } from 'react-router-dom';
import baseUrl from '../../http/backend-base-url'


export default class CardSessionFavoris extends Component {
    constructor(props){
        super(props)
        this.state = {
            baseUrl: baseUrl,

        }
    }

    componentDidMount(){
        //console.log(this.props.moduleInfos)
    }
    
    render(){
        var progression = parseInt(this.props.progression)
        var imgurl = this.state.baseUrl + this.props.moduleInfos.logo
        // console.log(progression)
        return (
            <div className="col-md-4">
                <div className="card">
                    <img className="img-course-on-card-cours" src={`${this.props.moduleInfos.logo !== null ? imgurl : "/img/courses/cours2.png"}`} alt=""/>
                    <div className="content-course-on-card-cours">
                        <NavLink className="titre-course-on-card-cours" to={`/cours-details/${this.props.moduleInfos.id}`}>
                            {this.props.moduleInfos.name}
                        </NavLink>
                        <div className="row m-t-30">
                            <div className="col-xl-10 col-lg-9 col-md-9 col-sm-10 col-9 p-b-10 flex-row info-content-course-on-card-cours itm-center">
                                <div className="progress">
                                    
                                    <div 
                                        className="progress-bar bg-theme-light" 
                                        role="progressbar" 
                                        style={{width:`${this.props.progression}%`}} 
                                        // eslint-disable-next-line
                                        aria-valuenow={`${progression}`} 
                                        aria-valuemin="0" 
                                        aria-valuemax="100">

                                    </div>
                                </div>
                                <p className="porcentage-progressbar-module-details-page-cours">{`${this.props.progression}%`}</p>
                            </div>
                            
                            <div className="col">
                                <NavLink to={`/cours-details/${this.props.moduleInfos.id}`}>
                                    <PlayCircleFilledWhiteIcon className="play-icon-course-on-card-cours" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
