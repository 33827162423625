import React, { Component } from "react";
// import FeatherIcon from 'feather-icons-react';
import { NavLink } from "react-router-dom";
import axios from "axios";
import api from "../../http/global-vars";
import { formatDate } from "../../utils";
import ForumSkeleton from "./ForumSkeleton";
import './paginate.css';
import { ToastContainer, toast } from "react-toastify";
import baseUrl from '../../http/backend-base-url'
import PaginationComponent from '../../modules/Pagination/Pagination'


export default class ForumCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            api: api,         
            loading: false,
            isNoItem: false,
            per_page: 4,
            current_page: 1,
            total_data: "",
            currentList: []
        }
    }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.onRef(this);
    const timer = setTimeout(() => {
      this._getForums();
      this.setState({ loading: false });
    }, 3000);
    // Cancel the timer while unmounting
    return () => clearTimeout(timer);
  }

    paginate = (event) => {
        event.preventDefault();
        this.setState({ currentPage: Number(event.target.textContent) });
    }

    _getForums = async () => {
        let { api } = this.state;
        let url = api + 'forum/'
        try {
            let res = await axios.get(url, {
                headers: { 'Authorization': `Bearer ${localStorage.userToken}` }
            });
            // console.log('child')
            let data = res.data['results'];
            if (data) {
              this.setState({ 
                data: data ,
                total_data: data.length
              })  
              this.formatData();
            } else {
                this.setState({isNoItem: true})
                toast.error("Pas de forum pour le moment !");
            }
            
        } catch (error) {
            console.log(error.message)
            toast.error(
              "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
            );
        }
    }

    formatData() {
      const indexOfLastPost = this.state.current_page * this.state.per_page;
      const indexOfFirstPage = indexOfLastPost - this.state.per_page;
  
      const currentList = this.state.data.slice(
        indexOfFirstPage,
        indexOfLastPost
      );
  
      this.setState({ currentList });
    }
  
    handleClick = (number) => {
      this.setState({
        current_page: number
      }, () => {
        this.formatData();
      });
    };

    render() {
        let renderForum = [];
        const { per_page, total_data, current_page, currentList, loading } = this.state;
        console.log('forum', currentList)
        if (loading) {
          return <ForumSkeleton 
          />;
        } else {
            renderForum = currentList.map((item) => (
              <div className="card-dashboard m-b-30" key={item.id}>
                <div className="row">
                  <div className=" col-xl-5 col-lg-5 col-md-6">
                    <strong className="forum-module">
                      {item.module?.name}
                    </strong>
                    <NavLink className="titre-forum-card" to={`/details-forum/${item.id}`}>
                      {item.name}
                    </NavLink>
                    <p className="publish-date-forum-card m-b-20">
                      Le {formatDate(item.created_at)}
                    </p>
                  </div>
                  <div className=" col-xl-4 col-lg-3 col-md-2 col-sm-6">
                    <p className="nbre-message-forum-card">
                      {item.number_message}
                    </p>
                    <p className="text-forum-card fs-12 fw-300 m-b-20">
                      commentaire(s)
                    </p>
                  </div>
                  <div className=" col-xl-3 col-lg-4 col-md-4 col-sm-6 dis-flex m-b-10">
                    <img
                      className="avatar-auteur-forum-card"
                      src={baseUrl + item.creator_id.avatar}
                      alt="Avatar"
                    />
                    <p className="text-forum-card fs-12 fw-300">
                      Par{" "}
                      <strong>
                        {item.creator_id.first_name +
                          " " +
                          item.creator_id.last_name}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            ));
            // console.log("render", forumPerpage);
        }

        return (
          <div>
            {renderForum.length !== 0 ? (
              renderForum
            ) : (
              <>
                <ForumSkeleton />
              </>
            )}
            <PaginationComponent
              per_page={per_page}
              current_page={current_page}
              total_data={total_data}
              handleClick={this.handleClick}
            />
            {this.state.isNoItem? (
              <ToastContainer  />
            ) : (
              ""
            )}
          </div>
        );
    }
}
