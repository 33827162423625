import React, { Component } from "react";
import ImagePicker from "react-image-picker";
import "react-image-picker/dist/index.css";
import FeatherIcon from "feather-icons-react";
import baseUrl from "../../http/backend-base-url";
import api from "../../http/global-vars";
import axios from 'axios' 
// react-image-picker/dist/index.css

export default class ChooseImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.image,
      image: [],
      selectedIndex: 0,
      imageList: [],
      responses: [],
      imageMap: [],
      isDone: false,
      baseUrl: baseUrl,
      score: 0,
      endSession: false,
      selectedId: null,
      module_id:'',
      token: window.localStorage.getItem("userToken"),
      requestConfig: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      },
      userID: window.localStorage.getItem("userID"),
      api: api,
    };
    // this.onPick = this.onPick.bind(this);
  }

  componentDidMount() {
    /* eslint no-use-before-define: 0 */
    let imageMap = this.props.image[0]?.list_question[0].list_responses.map(
      (image, i) => {
        this.state.imageList.push(this.state.baseUrl + image.image);
        this.state.imageMap.push({
          id: image.id,
          value: i,
          is_right_choice: image.is_right_choice,
          image: this.state.baseUrl + image.image,
        });
        return {
          id: image.id,
          value: i,
          is_right_choice: image.is_right_choice,
          image: this.state.baseUrl + image.image,
          score: this.props.image[0]?.list_question[0].score,
        
        };
      }
    );
    //console.log(imageMap)
    this.setState({ imageMap });

    this._getModuleBySession();
    window.scrollTo(0, 0);
  }

  _getModuleBySession = async () => {
   /*  const { api } = this.state;
    const url = api + `session/${this.props.sessionId}/`;
    try {
      const res = await axios.get(url, this.state.requestConfig);
      if (res.status === 201 || 200) {        
        let data = res['data'];
        this.setState({ module_id: data.module_id });
      }
    } catch (error) {
      console.log(error.message);
    } */
    if (this.props.sessionId) {
      const url = api + "session/" + this.props.sessionId;
      axios
        .get(url, this.state.requestConfig)
        .then((response) => {
          const last = this.props.sessions.filter(
            (d) => d.module_id === response.data.module_id
          );
          let data = response["data"];
          this.setState({ session: last, module_id: data.module_id });
          //console.log("session", last);
        })
        .catch((e) => {
          console.log("e", e);
        });
    }

    if (this.props.moduleId) {
      this.setState({ module_id: this.props.moduleId });
    }
  }

  updateProgres = async () => {
    const { api } = this.state;
    const mod = this.props.usermodules.filter((v) => v?.module?.id === this.state.module_id)
    console.log('mod', this.state.module_id)
    const url = api + `userdomodule/${mod[0]?.id}/`;
    let progression ;
    if (this.props.progression) {
      progression = this.props.progression
    }
    let send = {
      progression: progression
    }
    try {
      if (send.progression > mod[0]?.progression) {
        const res = await axios.put(url, send, this.state.requestConfig);
        console.log(res.data);
      } else {
        console.log('continu')
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  handleChange = () => {
    this.setState({ isDone: true });
  };

  getScore = () => {
    let score = 0;
    let responses = [];
    for (let i = 0; i < this.state.imageMap.length; i++) {
      const element = this.state.imageMap[i];
      
      for (let j = 0; j < this.state.image.length; j++) {
       
        const elt = this.state.image[j]; 
        
        if (element.value === elt.value) {
          responses.push(element)
         // score ++;
        }
        
      }
      
    }
    console.log('responses', responses)
    let allRight = responses.filter(r => r?.is_right_choice === true)
    console.log('allRight', allRight)
    let checker = (arr) => arr.every((v) => v.is_right_choice === true);
    if (checker(responses) === true && responses.length === allRight.length) {
      score = this.state.images[0].list_question[0]?.score;
    } else {
      for (let i = 0; i < responses.length; i++) {
        const element = responses[i];
        if (element.is_right_choice === true) {
          score ++;
        } else {
          score --;
        }
      }
    }
    //console.log(score)
    this.setState({ score, responses });
    let check = (arr) => arr.every((v) => parseInt(v?.contenu?.id) !== this.state.images[0]?.contenu);
 // console.log("check contenu", check(this.props.usercontenus), this.state.images[0]);
    const sendData = {
      score: this.state.score,
      user: parseInt(this.state.userID),
      contenu: this.props.image[0]?.contenu,
    };
   // console.log(sendData);
    const url = api + "userdocontenu/";
    if (check(this.props.usercontenus) !== false) {
      axios
      .post(url, sendData, this.state.requestConfig)
      .then((data) => {
        // console.log("data post", data);
       // let res=data['data']
        this.updateProgres();
      })
      .catch((e) => {
        console.log("e post", e.response);
      });
      
    }
    //this.updateProgres();
    if (this.props.last &&this.props.last[0]?.id === this.props.image[0]?.id) {
      // console.log("END");
      const Data = {
        user: parseInt(this.state.userID),
        session: this.props.sessionId,
      };
      var elementPos = this.props.sessions
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.sessionId);
      var objectFound = this.props?.sessions[elementPos + 1];
      //console.log("qst", this.state.selectedId);
      axios
        .post(api + "userdosession/", Data, this.state.requestConfig)
        .then((data) => {
          console.log("data", data.data);
        })
        .catch((e) => {
          console.log("e", e.response);
        });
      this.setState({ endSession: true, selectedId: objectFound?.id });
    }
  };

  _redirect = () => {
    window.scrollTo(0, 0);
    return (window.location = `/session-start/session/${this.state.selectedId}`);
  };

  onScroll = () => {
    window.scrollTo(0, 0);
  }

  onPick = (image) => {
    this.setState({ image });
  };

  render() {
    //let test = this.state.imageMap.filter(p => p.is_right_choice === true).length
    let { images, image, isDone, score } = this.state;
    let totalScore = images[0].list_question[0]?.score;
    //console.log(images)
    return (
      <div>
        <div>
          <p className="fw-400 f-f-work text-dark fs-14">
            A - Regarde les photos suivantes.
          </p>
          <p className="m-b-50 fw-400 f-f-work text-dark fs-14">
            B - Sélectionnes toutes les images où les personnes communiquent.
          </p>
          <p className="m-b-50 fw-400 f-f-work text-dark fs-14">
            <b>{images[0]?.title}.</b>
          </p>
          {isDone === false ? (
            <div className="m-b-50 m-t-10">
              <ImagePicker
                images={this.state.imageList.map((image, i) => ({
                  src: image,
                  value: i,
                }))}
                onPick={this.onPick}
                multiple
              />
            </div>
          ) : (
            <>
              <div className=" m-b-50 m-t-10">
                <div className="image_picker m-b-50 m-t-10">
                  <h4 className="m-b-35 f-f-work text-dark reponse-dnd-title">Ta Réponse</h4>
                  {this.state.responses?.map((item, i) => {
                    return (
                      <div
                      className={item.is_right_choice === true
                          ? "responsive true"
                          : "responsive false"}
                        key={item.id}
                      >
                        <img
                          src={item.image}
                          className="thumbnail mobile"
                          alt={`quiz${item.id}`}
                        />
                        <div className="checked"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="resp m-b-50">
                <div className="image_picker m-b-50 m-t-20">
                <h4 className="m-b-35 f-f-work text-dark reponse-dnd-title">Solution</h4>
                  {this.state.imageMap?.map((item, i) => {
                    return (
                      <div
                        className={
                          item.is_right_choice === true
                            ? "responsive true"
                            : "responsive false"
                        }
                        key={item.id}
                      >
                        <img
                          src={item.image}
                          className="thumbnail mobile"
                          alt={`quizzy${item.id}`}
                        />
                        <div className="checked"></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>

        {/* <button type="button" onClick={() => console.log(this.state.image)}>OK</button>   */}
        {isDone === false && image.length ? (
          <div className="row dis-flex justify-content-end m-b-20 m-t-50 ">
            <div className="col-md-3 col-sm-6 m-b-10 response-btn-container">
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                data-toggle="modal"
                data-target={`#imageSuccess${images[0]?.id}`}
                onClick={this.getScore}
              >
                Envoyer
              </button>
            </div>
          </div>
        ) : null}
        <div className="row dis-flex justify-content-end m-b-10 m-t-100 slider-actions-btn">
          <div className="col-md-3 col-sm-6 m-b-10">
            <a
              className="btn-theme-light dis-flex justify-content-center fw-600"
              href="#carouselSliderContentDetailsSession"
              role="button"
              data-slide="prev"
              onClick={this.onScroll}
            >
              Précédent
            </a>
          </div>
          <div className="col-md-3 col-sm-6 m-b-10">
            {/* {isDone === true && !this.state.endSession ?
              <button
                className="btn-theme dis-flex justify-content-center fw-600"
                data-dismiss="modal"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                onClick={this.onScroll}
              >
                Suivant
              </button> :
              <button
                className="btn-theme disabled dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                disabled
              >
                Suivant
              </button>
            } */}
            {this.props?.usercontenus.every(
              (v) =>
                parseInt(v?.contenu?.id) !== images[0]?.contenu
            ) === false ||  this.state.isDone === true ? (
              !this.state.endSession  ? (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  data-dismiss="modal"
                  href="#carouselSliderContentDetailsSession"
                  data-slide="next"
                  onClick={this.onScroll}
                >
                  Suivant
                </button>
              ) : (
                <button
                  className="btn-theme dis-flex justify-content-center fw-600"
                  onClick={this._redirect}
                  data-dismiss="modal"
                >
                  Suivant
                </button>
              )
            ) : (
              <button
                className="btn-theme disabled dis-flex justify-content-center fw-600"
                href="#carouselSliderContentDetailsSession"
                data-slide="next"
                disabled
              >
                Suivant
              </button>
            )}
          </div>
        </div>

        <div
          className="modal fade"
          id={`imageSuccess${images[0]?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-notify modal-success"
            role="document"
          >
            <div className="modal-content">
              {/* <div className="modal-header">
                <p className="heading lead"></p>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="white-text">
                    &times;
                  </span>
                </button>
              </div> */}

              <div className="modal-body">
                <div className="text-center">
                  {/* <i className="fas fa-check-circle fa-4x mb-3 animated rotateIn"></i> */}
                  <FeatherIcon
                    color="green"
                    icon="check-circle"
                    className="fa-4x mb-3 animated rotateIn"
                  />
                  <h4 className="parag fs-20 fw-600">
                    Vous avez obtenu{" "}
                    <span className="scores">{score} / {totalScore}  points</span>
                  </h4>
                  <p className="pop-title fw-600">
                  {((score * 100) / totalScore) <= 49 ? ('Oups ! Peut-être que tu dois répéter certains contenus ? Mais ne t’en fais pas, tu as encore le temps de t’améliorer !') 
                      : ((score * 100) / totalScore) >= 50 && ((score * 100) / totalScore) <=59 ? 
                      ('Bravo ! Tu peux encore t’améliorer, mais c’est un bon début ! ') 
                      : ((score * 100) / totalScore) >= 60 && ((score * 100) / totalScore) <=90 ? 
                      ('Félicitations ! Tu as déjà beaucoup appris. Continue comme ça !') : 
                      ('Wow, génial ! Tu as atteint le meilleur score. Félicitations !')
                    }
                  </p>
                  
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  data-dismiss="modal"
                  className="btn btn-theme fw-400 fs-15 justify-content-start"
                  onClick={this.handleChange}
                >
                  VOIR LE RESULTAT{" "}
                </button>

                {!this.state.endSession ? (
                  <a
                    type="button"
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    data-dismiss="modal"
                    onClick={this.onScroll}
                    href="#carouselSliderContentDetailsSession"
                    data-slide="next"
                  >
                    CONTINUER
                  </a>
                ) : (
                  <button
                    className="btn btn-outline btn-theme-light waves-effect f-w-400 fs-15"
                    onClick={this._redirect}
                    data-dismiss="modal"
                  >
                    CONTINUER
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
